import React from 'react';
import { useState, useEffect } from 'react';
import {
  listadoCatalogos,
  listadoProductos,
  listadoSucursales,
  listadoCategorias,
  listarVendedores,
  actualizarCarrito,
  datosUsuario,
  listadoPaises,
  logout,
  listadoPedidosB2bRealTime,
  listaClientesVendedor,
  listadoPedidos,
  listadoPedidosRealTimeAR,
  listadoPedidosRealTimeMX,
} from 'firebase/client';
import { useRouter } from 'next/router';
import firebase from 'firebase';
import getpagos from 'services/getpagos';
import playalert from 'services/playalert';

let AdminContext = React.createContext();
let { Provider, Consumer } = AdminContext;

function AdminProvider({ children }) {
  const [pais, setPais] = useState(null);
  const [paises, setPaises] = useState([])
  const [pedidos, setPedidos] = useState(null);
  const [pedidosB2b, setPedidosB2b] = useState([]);
  const [productos, setProductos] = useState([]);
  const [catalogos, setCatalogos] = useState([]);
  const [pagos, setPagos] = useState(null);
  const [pagosPendientes, setPagosPendientes] = useState(null);
  const [pagosRejected, setPagosRejected] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [carrito, setCarrito] = useState(null);
  const [total, setTotal] = useState(null);
  const [detalle, setDetalle] = useState(null);
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actualizar, setActualizar] = useState(false);
  const [menuActivo, setMenuActivo] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalAgregarProducto, setModalAgregarProducto] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isShowOptions, setIsShowOptions] = useState(false);
  const [clickeable, setClickeable] = useState(true);
  const [anulado, setAnulado] = useState(false);
  const [modalProducts, setModalProducts] = useState(false);
  const [currentItemProducts, setCurrentItemProducts] = useState({});
  const [currentListaProductos, setCurrentListaProductos] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalCategoryCreate, setModalCategoryCreate] = useState(false);
  const [currentItemCategory, setCurrentItemCategory] = useState({});
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  const [currentItemCatalogos, setCurrentItemCatalogos] = useState({});
  const [modalCatalogosEdit, setModalCatalogosEdit] = useState(false);
  const [modalCatalogoCreate, setModalCatalogoCreate] = useState(false);
  const [modalSucursalCreate, setModalSucursalCreate] = useState(false);
  const [currentItemSucursales, setCurrentItemSucursales] = useState({});
  const [modalSucursalesEdit, setModalSucursalesEdit] = useState(false);
  const [usuarioLogueado, setUsuarioLogueado] = useState(false);
  const [notificaciones, setNotificaciones] = useState(false);
  const router = useRouter();
  const [rol, setRol] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [modalMessageCreate, setModalMessageCreate] = useState(false);
  const [modalComprobante, setModalComprobante] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [modalRechazar, setModalRechazar] = useState(false);
  const [vendedores, setVendedores] = useState(null);
  const [currentItemVendedores, setCurrentItemVendedores] = useState({});
  const [currentItemClients, setCurrentItemClients] = useState({});
  const [modalEditVendedor, setModalEditVendedor] = useState(false);
  const [modalCreateVendedor, setModalCreateVendedor] = useState(false);
  const [modalCreateClient, setModalCreateClient] = useState(false);
  const [modalEditClient, setModalEditClient] = useState(false);
  const [modalDeleteProducts, setModalDeleteProducts] = useState(false);
  const [clientes, setClientes] = useState(null);
  const [dias, setDias] = useState(3);
  const [currentDeleteItem, setCurrentDeleteItem] = useState({});
  const [hayNuevoPedido, setHayNuevoPedido] = useState(false);
  const [modalFacturas, setModalFacturas] = useState(false);
  const [currentItemFactura, setCurrentItemFactura] = useState({});
  const [pedidosCargados, setPedidosCargados] = useState(false)
  const [currentNextDelivery, setCurrentNextDelivery] = useState(null)
  const [modalQR, setModalQR] = useState(false)

  //Listar clientes de vendedores
  useEffect(() => {
    pais !== null && listaClientesVendedor(pais).then(setClientes);
  }, [pais, actualizar]);

  useEffect(() => {
    pedidos !== null &&
    setPedidosCargados(true)
  }, [pedidos])

  //define usuario valido
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user.uid) {
        datosUsuario(user.uid).then((res) => {
          setRol(res);
          if (
            res.rol === 'superadmin' ||
            res.rol === 'admin' ||
            res.rol === 'god' ||
            res.rol === 'supergod'
          ) {
            setUsuarioLogueado(true);
            router.push(`/${res.pais}/pedidos`);
          } else {
            logout();
            setUsuarioLogueado(false);
          }
        });
      } else {
        setUsuarioLogueado(false);
      }
    });
  }, []);

  // Listado Paises

  useEffect(() => {
    listadoPaises().then(setPaises)
  }, [])

  //Pedidos CGB

  useEffect(() => {
      pais !== null &&
      listadoPedidos({ pais, dias }).then((res) => {
        setPedidos(res);
        pais === 'ar' &&
          dias === 3 &&
          sessionStorage.setItem('pedidosAR', res.length);
        pais === 'mx' &&
          dias === 3 &&
          sessionStorage.setItem('pedidosMX', res.length);
        setClickeable(true);
        setHayNuevoPedido(false);
      });
  }, [pais, dias, hayNuevoPedido]);

  // Pedidos CGB

  useEffect(() => {
    pais !== null &&
    pais === 'mx' &&
      listadoPedidosRealTimeMX(pais, (size) => {
        if (
          sessionStorage.getItem('pais') == 'mx' &&
          size > parseInt(sessionStorage.getItem('pedidosMX'))
        ) {
          playalert();
          setHayNuevoPedido(true);
        }
      });
    pais !== null &&
    pais === 'ar' &&
      listadoPedidosRealTimeAR(pais, (size) => {
        if (
          sessionStorage.getItem('pais') == 'ar' &&
          size > parseInt(sessionStorage.getItem('pedidosAR'))
        ) {
          playalert();
          setHayNuevoPedido(true);
        }
      });
  }, [pais]);

  //Pedidos B2B

  useEffect(() => {
      pais !== null &&
      listadoPedidosB2bRealTime(pais, (docs) => {
        setPedidosB2b(docs);
        setClickeable(true);
        if (
          sessionStorage.getItem('cantidad_pedidosb2b') !== undefined &&
          sessionStorage.getItem('cantidad_pedidosb2b') === docs.length
        ) {
        } else {
          sessionStorage.setItem('cantidad_pedidosb2b', docs.length);
        }
      });
  }, [pais]);

  // Catalogos

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      pais !== null &&
      listadoCatalogos(pais).then((res) => {
        setCatalogos(res);
      });
    }
    return () => (isSubscribed = false);
  }, [pais, actualizar]);

  // Productos

  useEffect(() => {
    pais !== null &&
      listadoProductos(pais).then((res) => {
        setProductos(res);
      });
  }, [pais, actualizar]);

  // Sucursales

  useEffect(() => {
    pais !== undefined &&
      listadoSucursales(pais).then((res) => {
        setSucursales(res);
        
      });
  }, [pais, actualizar]);

  //Vendedores
  useEffect(() => {
    pais !== null &&
      listarVendedores(pais).then((res) => {
        setVendedores(res);
      });
  }, [pais, actualizar]);

  // Categorias

  useEffect(() => {
    pais !== null &&
    listadoCategorias(pais).then((res) => {
      setCategorias(res);
      setLoading(false);
    });
  }, [pais, actualizar]);

  // Pagos

  useEffect(() => {
    pais !== null &&
    getpagos(pais).then((res) => {
      setPagos(
        res.sort((a, b) =>
          a.data.fecha._seconds < b.data.fecha._seconds
            ? 1
            : b.data.fecha._seconds < a.data.fecha._seconds
            ? -1
            : 0
        )
      );
      setPagosPendientes(
        res.filter((item) => item.data.estado === 'pendiente').length
      );
      setPagosRejected(
        res.filter(
          (item) =>
            item.data.estado === 'rechazado' &&
            (item.data.contacto === undefined || false)
        ).length
      );
      setActualizar(false);
    });
  }, [pais, actualizar]);

  //Borrar Item Carrito

  const handleBorrar = (pedido, productid, subtotalitem, descuento, b2b) => {
    let carritoActual = carrito;
    let nuevoDetalle = '';
    carritoActual.forEach((element, index) => {
      if (element.productid === productid) {
        //se borra el producto del carrito
        carritoActual.splice(index, 1);
      }
    });

    carritoActual.forEach((element, index) => {
      nuevoDetalle =
        index === 0
          ? nuevoDetalle + subtotalitem > 0 &&
            `${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`
          : nuevoDetalle + subtotalitem > 0 &&
            ` | ${
              element.medida === 'gr'
                ? element.cantidad * element.escala < 1000
                  ? element.cantidad * element.escala
                  : (element.cantidad * element.escala) / 1000
                : element.cantidad * element.escala
            } ${
              element.medida === 'gr' &&
              element.cantidad * element.escala >= 1000
                ? 'kg'
                : element.medida
            } x ${element.nombre}`;
    });
    setCarrito(carritoActual);
    actualizarCarrito(
      pais,
      pedido,
      subtotalitem > 0 ? descuento : descuento + subtotalitem,
      carrito,
      parseFloat(
        subtotalitem > 0
          ? ((total * 100 - subtotalitem * 100) / 100).toFixed(2)
          : total
      ),
      nuevoDetalle,
      b2b
    ).then(() => {
      setActualizar(true);
      setCurrentItem({
        ...currentItem,
        total: parseFloat(
          subtotalitem > 0
            ? ((total * 100 - subtotalitem * 100) / 100).toFixed(2)
            : total
        ),
      });
      setClickeable(true);
    });
  };

  const agregarProductoEnPedido = async (item, pedido, descuento, b2b) => {
    let carritoActual = carrito;
    carritoActual.push(item);
    setCarrito(carritoActual);
    let nuevoDetalle =
      detalle + item.precio > 0 &&
      `| ${
        item.medida === 'gr'
          ? item.cantidad * item.escala < 1000
            ? item.cantidad * item.escala
            : (item.cantidad * item.escala) / 1000
          : item.cantidad * item.escala
      } ${
        item.medida === 'gr' &&
        (item.cantidad + item.cantidad) * item.escala >= 1000
          ? 'kg'
          : item.medida
      } x ${item.nombre}`;
    if (item.precio > 0) {
      setTotal(
        parseFloat(((total * 100 + item.subtotal * 100) / 100).toFixed(2))
      );
    }
    actualizarCarrito(
      pais,
      pedido,
      descuento,
      carrito,
      item.precio > 0 ? total + item.cantidad * item.precio : total,
      nuevoDetalle,
      b2b
    ).then(() => {
      setActualizar(!actualizar);
      setCurrentItem({
        ...currentItem,
        total: parseFloat(
          item.precio > 0
            ? ((total * 100 + item.cantidad * item.precio * 100) / 100).toFixed(
                2
              )
            : total
        ),
      });
      setClickeable(true);
    });
  };

  const nuevoCarrito = async (item, pedido, descuento, b2b) => {
    let newElement;
    let carritoActual = carrito;
    let existe = false;

    //ya existe un producto en el carrito
    let nuevoDetalle = '';
    await carritoActual.forEach((element, index) => {
      if (element.productid === item.productid) {
        existe = true;
        newElement = {
          cantidad: element.cantidad + item.cantidad,
          nombre: item.nombre,
          imagen: item.imagen,
          precio: parseFloat(item.precio),
          id_erp_mx: item.id_erp_mx,
          productid: item.productid,
          subtotal: parseFloat(
            ((element.subtotal * 100 + item.subtotal * 100) / 100).toFixed(2)
          ),
          medida: element.medida,
          escala: element.escala,
          datos_producto: item.datos_producto,
        };
        carritoActual.splice(index, 1, newElement);
        setCarrito(carritoActual);
        nuevoDetalle =
          index === 0
            ? nuevoDetalle + item.precio > 0 &&
              `${
                element.medida === 'gr'
                  ? (element.cantidad + item.cantidad) * element.escala < 1000
                    ? (element.cantidad + item.cantidad) * element.escala
                    : ((element.cantidad + item.cantidad) * element.escala) /
                      1000
                  : (element.cantidad + item.cantidad) * element.escala
              } ${
                item.medida === 'gr' &&
                (element.cantidad + item.cantidad) * item.escala >= 1000
                  ? 'kg'
                  : item.medida
              } x ${item.nombre}`
            : nuevoDetalle +
              ` | ${
                element.medida === 'gr'
                  ? (element.cantidad + item.cantidad) * element.escala < 1000
                    ? (element.cantidad + item.cantidad) * element.escala
                    : ((element.cantidad + item.cantidad) * element.escala) /
                      1000
                  : (element.cantidad + item.cantidad) * element.escala
              } ${
                item.medida === 'gr' &&
                (element.cantidad + item.cantidad) * item.escala >= 1000
                  ? 'kg'
                  : item.medida
              } x ${item.nombre}`;
      } else {
        nuevoDetalle =
          index === 0
            ? nuevoDetalle + item.precio > 0 &&
              `${
                element.medida === 'gr'
                  ? element.cantidad * element.escala < 1000
                    ? element.cantidad * element.escala
                    : (element.cantidad * element.escala) / 1000
                  : element.cantidad * element.escala
              } ${
                element.medida === 'gr' &&
                (element.cantidad + element.cantidad) * element.escala >= 1000
                  ? 'kg'
                  : element.medida
              } x ${element.nombre}`
            : nuevoDetalle +
              ` | ${
                element.medida === 'gr'
                  ? element.cantidad * element.escala < 1000
                    ? element.cantidad * element.escala
                    : (element.cantidad * element.escala) / 1000
                  : element.cantidad * element.escala
              } ${
                element.medida === 'gr' &&
                (element.cantidad + element.cantidad) * element.escala >= 1000
                  ? 'kg'
                  : element.medida
              } x ${element.nombre}`;
      }
      setDetalle(nuevoDetalle);
    });
    await actualizarCarrito(
      pais,
      pedido,
      descuento,
      carrito,
      parseFloat(
        item.precio > 0
          ? ((total * 100 + item.cantidad * item.precio * 100) / 100).toFixed(2)
          : total
      ),
      nuevoDetalle,
      b2b
    ).then(() => {
      setActualizar(!actualizar);
      setCurrentItem({
        ...currentItem,
        total: parseFloat(
          item.precio > 0
            ? ((total * 100 + item.cantidad * item.precio * 100) / 100).toFixed(
                2
              )
            : total
        ),
      });
    });
    return true
  };

  return (
    <Provider
      value={{
        pais,
        setPais,
        actualizar,
        setActualizar,
        menuActivo,
        setMenuActivo,
        catalogos,
        loading,
        sucursales,
        nuevoCarrito,
        productos,
        pedidos,
        setPedidos,
        modal,
        setModal,
        currentItem,
        setCurrentItem,
        isShowOptions,
        setIsShowOptions,
        anulado,
        setAnulado,
        carrito,
        setCarrito,
        setTotal,
        setDetalle,
        clickeable,
        setClickeable,
        handleBorrar,
        modalAgregarProducto,
        setModalAgregarProducto,
        modalProducts,
        setModalProducts,
        currentItemProducts,
        setCurrentItemProducts,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        currentListaProductos,
        setCurrentListaProductos,
        agregarProductoEnPedido,
        modalCategoryCreate,
        setModalCategoryCreate,
        currentItemCategory,
        setCurrentItemCategory,
        modalEditCategory,
        setModalEditCategory,
        valueTabs,
        setValueTabs,
        currentItemCatalogos,
        setCurrentItemCatalogos,
        modalCatalogosEdit,
        setModalCatalogosEdit,
        modalCatalogoCreate,
        setModalCatalogoCreate,
        modalSucursalCreate,
        setModalSucursalCreate,
        currentItemSucursales,
        setCurrentItemSucursales,
        modalSucursalesEdit,
        setModalSucursalesEdit,
        router,
        usuarioLogueado,
        setUsuarioLogueado,
        rol,
        setRol,
        notificaciones,
        setNotificaciones,
        pagosRejected,
        setPagosRejected,
        spinner,
        setSpinner,
        modalMessage,
        setModalMessage,
        modalMessageCreate,
        setModalMessageCreate,
        categorias,
        setCategorias,
        pagos,
        pagosPendientes,
        setModalComprobante,
        modalComprobante,
        currentPhoto,
        setCurrentPhoto,
        modalRechazar,
        setModalRechazar,
        vendedores,
        setVendedores,
        currentItemVendedores,
        setCurrentItemVendedores,
        modalEditVendedor,
        setModalEditVendedor,
        modalCreateVendedor,
        setModalCreateVendedor,
        modalCreateClient,
        setModalCreateClient,
        modalEditClient,
        setModalEditClient,
        clientes,
        setClientes,
        currentItemClients,
        setCurrentItemClients,
        pedidosB2b,
        setPedidosB2b,
        modalDeleteProducts,
        setModalDeleteProducts,
        currentDeleteItem,
        setCurrentDeleteItem,
        dias,
        setDias,
        modalFacturas,
        setModalFacturas,
        currentItemFactura,
        setCurrentItemFactura,
        paises, 
        pedidosCargados,
        setPedidosCargados,
        setPedidos,
        currentNextDelivery,
        setCurrentNextDelivery,
        modalQR,
        setModalQR
      }}
    >
      {children}
    </Provider>
  );
}

export { AdminProvider, Consumer as AdminConsumer, AdminContext };
