import firebase from 'firebase';
import getfechaanterior from 'services/getfechaanterior';

var firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);

!firebase.apps.length && firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const hoy = new Date();

//Login

export const loginWithEmail = async (email, pass) => {
  const user = await firebase
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch((error) => {
      return false;
    });
  return user;
};

export const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      window.location.reload(false);
    })
    .catch((error) => console.log(error));
};

export const datosUsuario = async (uid) => {
  const rolesRef = db.collection('roles')
  const rol = await rolesRef.doc(uid).get()
  console.log(rol.data(), 'jjj')
  return rol.data();
};

//Paises

export const listadoPaises = async () => {
  let paises = [];
  const paisesRef = await db.collection('paises').get()
  for(let pais of paisesRef.docs) {
    paises.push({ id: pais.id, data: pais.data() })
  }
  return paises;
};

//Catalogos

export const existeCatalogo = async (pais, nombre) => {
  let existe = false;
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .where('nombre', '==', nombre)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        existe = true;
      });
    });
  return existe;
};

export const actualizarCatalogo = async (pais, id, nombre) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(id)
    .update({
      nombre: nombre,
    });
  return;
};

export const nuevoCatalogo = async (pais, nombre) => {
  let respuesta = false;
  let productos;
  await listadoProductos(pais).then((res) => (productos = res));
  await existeCatalogo(pais, nombre.toLowerCase()).then(async (res) => {
    if (res) {
      respuesta = false;
    } else {
      await db
        .collection('paises')
        .doc(pais)
        .collection('catalogos')
        .add({
          nombre: nombre.toLowerCase(),
          ultima_actualizacion: hoy,
        })
        .then((docRef) => {
          productos.forEach(async (singleProducto) => {
            await db
              .collection('paises')
              .doc(pais)
              .collection('catalogos')
              .doc(docRef.id)
              .collection('productos')
              .doc(singleProducto.id)
              .set({
                ...singleProducto.data,
                habilitado_en_catalogo: false,
              });
          });
          respuesta = true;
        })
        .catch((error) => {
          console.log(error);
          respuesta = false;
        });
    }
  });
  return respuesta;
};

export const listadoCatalogos = async (pais) => {
  let catalogos = [];
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .orderBy('nombre', 'asc')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        catalogos.push({ id: doc.id, data: doc.data() });
      });
    });
  return catalogos;
};

export const borrarCatalogo = async (pais, id) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(id)
    .collection('categorias')
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        await db
          .collection('paises')
          .doc(pais)
          .collection('catalogos')
          .doc(id)
          .collection('categorias')
          .doc(doc.id)
          .delete()
          .then(() => {
            console.log('borrado');
          });
      });
    });
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(id)
    .collection('productos')
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        await db
          .collection('paises')
          .doc(pais)
          .collection('catalogos')
          .doc(id)
          .collection('productos')
          .doc(doc.id)
          .delete()
          .then(() => {
            console.log('borrado');
          });
      });
    });
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(id)
    .delete()
    .then(() => {
      console.log('borrado');
    });
  return;
};

// Nombre de Catalogo

export const nombreCatalogo = async (pais, catalogo) => {
  let nombre = '';
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(catalogo)
    .get()
    .then((doc) => {
      nombre = doc.data().nombre;
    });
  return nombre;
};

// Productos en un Catalogo

export const productosEnCatalogo = async (pais, catalogo) => {
  let productos = [];
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(catalogo)
    .collection('productos')
    .orderBy('nombre', 'asc')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        productos.push({ id: doc.id, data: doc.data() });
      });
    });
  return productos;
};

export const cambiarProductoHabilitado = async (
  pais,
  catalogo,
  producto,
  value
) => {
  if (producto === 'todos') {
    await db
      .collection('paises')
      .doc(pais)
      .collection('catalogos')
      .doc(catalogo)
      .collection('productos')
      .get()
      .then((snapshot) => {
        snapshot.forEach(async (doc) => {
          await db
            .collection('paises')
            .doc(pais)
            .collection('catalogos')
            .doc(catalogo)
            .collection('productos')
            .doc(doc.id)
            .update({
              habilitado_en_catalogo: value,
            });
        });
      });
  } else {
    await db
      .collection('paises')
      .doc(pais)
      .collection('catalogos')
      .doc(catalogo)
      .collection('productos')
      .doc(producto)
      .update({
        habilitado_en_catalogo: value,
      });
  }
  await db
    .collection('paises')
    .doc(pais)
    .collection('catalogos')
    .doc(catalogo)
    .update({
      ultima_actualizacion: hoy,
    });
  return;
};

// actualizar Estado de un Pedido

export const actualizarEstadoPedido = async (
  pais,
  pedido,
  estado,
  detalle_anulado
) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidos')
    .doc(pedido)
    .update({
      estado: estado,
      detalle_anulado: detalle_anulado,
      cambio_estado_backoffice: {
        fecha: new Date(),
        nuevo_estado: estado,
      },
    });
  return;
};

// actualizar Estado de un Pedido B2B

export const actualizarEstadoPedidoB2b = async (
  pais,
  pedido,
  estado,
  detalle_anulado
) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidosb2b')
    .doc(pedido)
    .update({
      estado: estado,
      detalle_anulado: detalle_anulado,
      fecha_confirmacion: estado === 'confirmado' ? new Date() : null
    });
  return;
};

// Actualizar Descuento de un Pedido

export const descuentoPedido = async (
  pais,
  pedido,
  descuento,
  total_con_descuento,
  b2b
) => {
  if (b2b) {
    await db
      .collection('paises')
      .doc(pais)
      .collection('pedidosb2b')
      .doc(pedido)
      .update({
        descuento: parseFloat(descuento),
        total_con_descuento: total_con_descuento,
      });
  } else {
    await db
      .collection('paises')
      .doc(pais)
      .collection('pedidos')
      .doc(pedido)
      .update({
        descuento: parseFloat(descuento),
        total_con_descuento: total_con_descuento,
      });
  }

  return true;
};

//Productos

export const publicarProducto = async (pais, producto) => {
  let respuesta = false;
  await db
    .collection('paises')
    .doc(pais)
    .collection('productos')
    .add({
      ...producto,
      nombre: producto.nombre.toLowerCase(),
      slug: producto.nombre.replace(/ /g, '-').replace(' ', '').toLowerCase()
    })
    .then(async (docRef) => {
      listadoCatalogos(pais).then(async (res) => {
        res.forEach(async (singleCatalogo) => {
          await db
            .collection('paises')
            .doc(pais)
            .collection('catalogos')
            .doc(singleCatalogo.id)
            .collection('productos')
            .doc(docRef.id)
            .set({
              ...producto,
              nombre: producto.nombre.toLowerCase(),
              slug: producto.nombre.replace(/ /g, '-').replace(' ', '').toLowerCase(),
              habilitado_en_catalogo: false
            });
        });
      });
      respuesta = true;
    })
    .catch((error) => {
      console.log(error);
      respuesta = false;
    });
  return respuesta;
};

export const actualizarProducto = async (pais, id, producto, imagen) => {
  let respuesta = true;
  try {
    const result = await db
      .collection('paises')
      .doc(pais)
      .collection('productos')
      .doc(id)
      .update({
        nombre: producto.nombre.toLowerCase(),
        odoo_product_id: producto.odoo_product_id,
        odoo_tax_id: producto.odoo_tax_id,
        odoo_measure_unit: producto.odoo_measure_unit,
        maximo: producto.maximo,
        descuento: producto.descuento,
        descuento_comunicacional: producto.descuento_comunicacional,
        categoria: producto.categoria,
        descripcion: producto.descripcion,
        observaciones: producto.observaciones,
        iva: producto.iva,
        codigo_erp_unidad_de_medida: producto.codigo_erp_unidad_de_medida,
        id_erp: producto.id_erp,
        // id_erp_mx: id_erp_mx,
        // categoria: categoria,
        id_erp_mx:
          pais === 'mx'
            ? {
                precio1: producto.idPrecio1,
                precio2: producto.idPrecio2,
                precio3: producto.idPrecio3,
                precio4: producto.idPrecio4,
                precio5: producto.idPrecio5,
              }
            : '',
        precios: {
          precio1: parseFloat(producto.precio1),
          precio2: parseFloat(producto.precio2),
          precio3: parseFloat(producto.precio3),
          precio4: parseFloat(producto.precio4),
          precio5: parseFloat(producto.precio5),
        },
        imagen: imagen,
        habilitado: producto.habilitado,
        metodo: producto.metodo,
        escala: producto.escala,
        slug: producto.nombre.replace(/ /g, '-').replace(' ', '').toLowerCase(),
        para_mayores: producto.para_mayores
      });
      const listaDeCatalogos = await listadoCatalogos(pais);
      
      let promiseList = []
      
      listaDeCatalogos.forEach(async (singleCatalogo) => {
        promiseList.push(
          db
          .collection('paises')
          .doc(pais)
          .collection('catalogos')
          .doc(singleCatalogo.id)
          .collection('productos')
          .doc(id)
          .update({
            nombre: producto.nombre.toLowerCase(),
            odoo_product_id: producto.odoo_product_id,
            odoo_tax_id: producto.odoo_tax_id,
            odoo_measure_unit: producto.odoo_measure_unit,
            maximo: producto.maximo,
            descuento: producto.descuento,
            descuento_comunicacional: producto.descuento_comunicacional,
            categoria: producto.categoria,
            descripcion: producto.descripcion,
            observaciones: producto.observaciones,
            iva: producto.iva,
            codigo_erp_unidad_de_medida: producto.codigo_erp_unidad_de_medida,
            id_erp: producto.id_erp,
            // id_erp_mx: id_erp_mx,
            // categoria: categoria,
            id_erp_mx:
              pais === 'mx'
                ? {
                    precio1: producto.idPrecio1,
                    precio2: producto.idPrecio2,
                    precio3: producto.idPrecio3,
                    precio4: producto.idPrecio4,
                    precio5: producto.idPrecio5,
                  }
                : '',
            precios: {
              precio1: producto.precio1,
              precio2: producto.precio2,
              precio3: producto.precio3,
              precio4: producto.precio4,
              precio5: producto.precio5,
            },
            imagen: imagen,
            habilitado: producto.habilitado,
            metodo: producto.metodo,
            escala: producto.escala,
            slug: producto.nombre
              .replace(/ /g, '-')
              .replace(' ', '')
              .toLowerCase(),
            para_mayores: producto.para_mayores
          })
        )
      });
      
      const editResult = await Promise.allSettled(promiseList)
      editResult.forEach(({status}) => {
        if(status !== 'fulfilled') {
          respuesta = false
        }
      })
    } catch (error) {
      console.log(error);
      respuesta = false;
    }
  return respuesta;
};

export const nombreCategoria = async (pais, categoria) => {
  let nombre = '';
  await db
    .collection('paises')
    .doc(pais)
    .collection('categorias')
    .doc(categoria)
    .get()
    .then((doc) => {
      nombre = doc.data().nombre;
    });
  return nombre;
};

export const listadoProductos = async (pais) => {
  let productos = [];
  await db
    .collection('paises')
    .doc(pais)
    .collection('productos')
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        productos.push({
          id: doc.id,
          data: doc.data(),
          nombre_categoria: '',
        });
        // await nombreCategoria(pais, doc.data().categoria).then((res) => {
        //   productos.push({
        //     id: doc.id,
        //     data: doc.data(),
        //     nombre_categoria: res,
        //   });
        // });
      });
    });

  return productos;
};

export const cambiarHabilitadoProducto = async (pais, producto, habilitado) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('productos')
    .doc(producto)
    .update({
      habilitado: habilitado,
    })
    .then(() => {
      listadoCatalogos(pais).then((res) => {
        res.forEach(async (singleCatalogo) => {
          await db
            .collection('paises')
            .doc(pais)
            .collection('catalogos')
            .doc(singleCatalogo.id)
            .collection('productos')
            .doc(producto)
            .update({
              habilitado: habilitado,
            });
        });
      });
    });
  return;
};

export const borrarProducto = async (pais, producto) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('productos')
    .doc(producto)
    .delete()
    .then(() => {
      listadoCatalogos(pais).then((res) => {
        res.forEach(async (singleCatalogo) => {
          await db
            .collection('paises')
            .doc(pais)
            .collection('catalogos')
            .doc(singleCatalogo.id)
            .collection('productos')
            .doc(producto)
            .delete()
            .then(console.log('listo'));
        });
      });
    });
  return;
};

//Sucursales

export const existeSucursal = async (nombre) => {
  let existe = false;
  await db
    .collection('sucursales')
    .where('nombre', '==', nombre)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        existe = true;
      });
    });
  return existe;
};

export const nuevaSucursal = async (sucursal) => {
  let respuesta = false;
  let password = sucursal.url + sucursal.altura;
  await firebase
    .auth()
    .createUserWithEmailAndPassword(sucursal.email, password)
    .then((user) => {
      console.log(firebase.auth().currentUser);
    });
  await existeSucursal(sucursal.nombre.toLowerCase()).then(async (res) => {
    if (res) {
      respuesta = false;
    } else {
      await db
        .collection('sucursales')
        .doc(sucursal.id_sap)
        .set({
          ...sucursal,
          administracion: {
            monto_pendiente: 0,
            pagos_pendientes: 0,
            ultima_fecha_aprobado: null,
            ultima_fecha_pendiente: null,
            ultimo_pago_aprobado: null,
          },
          estado_de_cuenta: {
            pago_inicial: 0,
            saldo: 0,
            total_comision: 0,
            total_pagos: 0,
            total_ventas: 0,
          },
        })
        .then((docRef) => {
          respuesta = true;
        })
        .catch((error) => {
          console.log(error);
          respuesta = false;
        });
    }
  });
  return respuesta;
};

export const resumenUltimaDeuda = async (sucursal) => {
  let fecha_ultima_entrega;
  let ventas = 0;
  let comision = 0;
  let descuentos = 0;
  await sucursal.fechas_entrega.forEach((singleFecha) => {
    if (singleFecha.seconds < new Date() / 1000) {
      fecha_ultima_entrega = singleFecha;
    }
  });
  await db
    .collection('paises')
    .doc(sucursal.pais)
    .collection('pedidos')
    .where('sucursal', '==', sucursal.url)
    .where('fecha_entrega', '==', fecha_ultima_entrega)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        if (
          doc.data().estado !== 'anulado' &&
          doc.data().estado !== 'pendiente'
        ) {
          ventas =
            ventas +
            (doc.data().descuento !== undefined
              ? doc.data().total_con_descuento
              : doc.data().total);
          comision =
            comision +
            (doc.data().total * doc.data().datos_sucursal.comision) / 100;
          descuentos =
            descuentos +
            (doc.data().descuento !== undefined && doc.data().descuento);
        }
      });
    });
  return {
    fecha_ultima_entrega:
      new Date(fecha_ultima_entrega.seconds * 1000).getDate() +
      '/' +
      (new Date(fecha_ultima_entrega.seconds * 1000).getMonth() + 1) +
      '/' +
      new Date(fecha_ultima_entrega.seconds * 1000).getFullYear(),
    ventas,
    comision,
    descuentos,
  };
};

export const listadoSucursales = async (pais) => {
  let sucursales = [];
  let sucursalesRef = db.collection('sucursales')
  let paisRef = await sucursalesRef.where('pais', '==', pais).get()
  for(let sucursal of paisRef.docs) {
    sucursales.push({
      id: sucursal.id,
      data: sucursal.data(),
      nombre_catalogo: '',
      ultima_semana: ''
    });
  }
  return sucursales;
};

export const borrarSucursal = async (id) => {
  await db
    .collection('sucursales')
    .doc(id)
    .delete()
    .then(() => {
      console.log('borrado');
    });
  return;
};

export const actualizarSucursal = async (id, sucursal) => {
  await db
    .collection('sucursales')
    .doc(id)
    .update({
      ...sucursal,
    });
  return true;
};

//Habilitar Pagos

export const estadoPagos = async (id, datos) => {
  await db.collection('sucursales').doc(id).update({
    feature_pagos: datos.habilitado,
    estado_de_cuenta: datos.estado_de_cuenta,
  });
  return true;
};

//Categorias

export const existeCategoria = async (pais, nombre) => {
  let existe = false;
  await db
    .collection('paises')
    .doc(pais)
    .collection('categorias')
    .where('nombre', '==', nombre)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        existe = true;
      });
    });
  return existe;
};

export const nuevaCategoria = async (pais, form, imagen) => {
  let respuesta = false;
  await existeCategoria(pais, form.nombre.toLowerCase()).then(async (res) => {
    if (res) {
      respuesta = false;
    } else {
      await db
        .collection('paises')
        .doc(pais)
        .collection('categorias')
        .add({
          nombre: form.nombre.toLowerCase(),
          descripcion: form.descripcion,
          layout: form.layout,
          imagen: imagen,
          slug: form.nombre.replace(/ /g, '-').replace(' ', '').toLowerCase(),
          orden: parseInt(form.orden),
        })
        .then((docRef) => {
          console.log('categoria grabada');
          respuesta = true;
        })
        .catch((error) => {
          console.log(error);
          respuesta = false;
        });
    }
  });
  return respuesta;
};

export const listadoCategorias = async (pais) => {
  let categorias = [];

  await db
    .collection('paises')
    .doc(pais)
    .collection('categorias')
    .orderBy('orden', 'asc')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        categorias.push({ id: doc.id, data: doc.data() });
      });
    });
  return categorias;
};

export const borrarCategoria = async (pais, id) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('categorias')
    .doc(id)
    .delete()
    .then(() => {
      console.log('borrado');
    });
  return true;
};

//Actualizar la categoria

export const actualizarCategoria = async (pais, id, form, imagen) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('categorias')
    .doc(id)
    .update({
      nombre: form.nombre,
      descripcion: form.descripcion,
      layout: form.layout,
      imagen: imagen,
    });
  return true;
};

const mapPedidosB2b = (doc) => {
  let pedidos = [];
  doc.map((singleDoc) => {
    if (singleDoc.data().estado !== 'en_proceso') {
      const data = singleDoc.data();
      const id = singleDoc.id;
      pedidos.push({
        id,
        data,
      });
    }
  });
  return pedidos;
};

// existeFactura

export const pedidoFacturado = async (sucursal, fecha, b2b) => {
  let respuesta = false
  !b2b &&
  await db
  .collection('sucursales')
  .doc(sucursal)
  .collection('facturas')
  .where('fecha_entrega', '==', new Date(fecha * 1000))
  .get()
  .then(snapshot => {
    snapshot.forEach(doc => {
        doc.id && (respuesta = true)
      })
    })
  return respuesta
}

// Asignar Pago Pendiente

export const pagoNoAsignado = async (sucursal, valor) => {
  await db
  .collection('sucursales')
  .doc(sucursal)
  .get()
  .then(async doc => {
    let estado_de_cuenta = doc.data().estado_de_cuenta
    await db
    .collection('sucursales')
    .doc(sucursal)
    .update({
      estado_de_cuenta: {
        ...estado_de_cuenta,
        pago_sin_asignar: estado_de_cuenta.pago_sin_asignar !== undefined ? estado_de_cuenta.pago_sin_asignar + parseFloat(valor) : parseFloat(valor)
      }
    })
  })
  return true
}

// Pedidos

export const listadoPedidos = async (datos) => {
  let misPedidos = [];
  let paisesRef = db.collection('paises')
  if(datos.dias !== 'todos') {
    const pedidosRef = await paisesRef.doc(datos.pais).collection('pedidos').where('fecha', '>=', getfechaanterior(datos.dias)).orderBy('fecha', 'desc').get()
    for(let pedido of pedidosRef.docs) {
      misPedidos.push({id: pedido.id, data: pedido.data()})
    }
  } else {
    const pedidosRef = await paisesRef.doc(datos.pais).collection('pedidos').orderBy('fecha', 'desc').get()
    for(let pedido of pedidosRef.docs) {
      misPedidos.push({id: pedido.id, data: pedido.data()})
    }
  }
  return misPedidos;
};

export const listadoPedidosRealTimeAR = (pais, callback) => {
  db.collection('paises')
    .doc('ar')
    .collection('pedidos')
    .where('fecha', '>=', getfechaanterior(3))
    .onSnapshot((docs) => {
      pais === 'ar' && callback(docs.size);
    });
};

export const listadoPedidosRealTimeMX = (pais, callback) => {
  db.collection('paises')
    .doc('mx')
    .collection('pedidos')
    .where('fecha', '>=', getfechaanterior(3))
    .onSnapshot((docs) => {
      pais === 'mx' && callback(docs.size);
    });
};

//Pedidos B2B

export const listadoPedidosB2bRealTime = (pais, callback) => {
  db
    .collection('paises')
    .doc(pais)
    .collection('pedidosb2b')
    .where('fecha_pedido', '>=', getfechaanterior(35))
    .orderBy('fecha_pedido', 'desc')
    .onSnapshot(({ docs }) => {
      const pedidos = mapPedidosB2b(docs);
      callback(pedidos);
    });
};

//id erp pos

export const idERP = async (url) => {
  let iderp = [];
  await db
    .collection('sucursales')
    .where('url', '==', url)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        iderp.push(doc.data().id_erp_pos);
      });
    });
  return iderp[0];
};

export const listadoPedidosDocs = async (pais) => {
  let misPedidos = [];
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidos')
    .where('fecha_entrega', '>=', getfechaanterior(7))
    .orderBy('fecha_entrega', 'desc')
    .orderBy('sucursal', 'asc')
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        misPedidos.push({
          id: doc.id,
          data: doc.data(),
          id_erp_pos: doc.data().datos_sucursal.id_erp_pos,
        });
      });
    });
  return misPedidos;
};

// actualizar Carrito de un Pedido

export const actualizarCarrito = async (
  pais,
  pedido,
  descuento,
  carrito,
  total,
  detalle,
  b2b
) => {
  if (b2b) {
    await db
      .collection('paises')
      .doc(pais)
      .collection('pedidosb2b')
      .doc(pedido)
      .update({
        carrito: carrito,
        total: total,
        detalle: detalle,
        descuento: descuento,
        total_con_descuento: total - descuento,
      });
    return;
  } else {
    await db
      .collection('paises')
      .doc(pais)
      .collection('pedidos')
      .doc(pedido)
      .update({
        carrito: carrito,
        total: total,
        detalle: detalle,
        descuento: descuento,
        total_con_descuento: total - descuento,
      });
    return;
  }
};

// Marcar Contacto

export const contactoRealizado = async (pais, pedido, contacto) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidos')
    .doc(pedido)
    .update({
      contacto: contacto,
    });
};

// Pagos

export const actualizarPago = async (
  sucursal,
  pago,
  estado,
  valor,
  value,
  administracion
) => {
  await db
    .collection('sucursales')
    .doc(sucursal)
    .collection('pagos')
    .doc(pago)
    .update({
      estado: estado,
      fecha_actualizacion: new Date(),
      valor: parseFloat(valor),
      comentario_rechazo: value,
    });
  estado === 'aprobado' &&
    (await db
      .collection('sucursales')
      .doc(sucursal)
      .update({
        administracion: {
          pagos_pendientes: administracion.pagos_pendientes - 1,
          monto_pendiente: administracion.monto_pendiente - valor,
          ultima_fecha_aprobado: new Date(),
          ultima_fecha_pendiente: new Date(
            administracion.ultima_fecha_pendiente._seconds * 1000
          ),
          ultimo_pago_aprobado: valor,
        },
      }));
  estado === 'rechazado' &&
    (await db
      .collection('sucursales')
      .doc(sucursal)
      .update({
        administracion: {
          ultima_fecha_pendiente: new Date(
            administracion.ultima_fecha_pendiente._seconds * 1000
          ),
          ultima_fecha_aprobado: new Date(
            administracion.ultima_fecha_aprobado._seconds * 1000
          ),
          pagos_pendientes: administracion.pagos_pendientes - 1,
          monto_pendiente: administracion.monto_pendiente - valor,
          ultimo_pago_aprobado: administracion.ultimo_pago_aprobado,
        },
      }));
  estado === 'pendiente' &&
    (await db
      .collection('sucursales')
      .doc(sucursal)
      .update({
        administracion: {
          ultima_fecha_pendiente: new Date(
            administracion.ultima_fecha_pendiente._seconds * 1000
          ),
          ultima_fecha_aprobado: new Date(
            administracion.ultima_fecha_aprobado._seconds * 1000
          ),
          pagos_pendientes: administracion.pagos_pendientes + 1,
          monto_pendiente: administracion.monto_pendiente + valor,
          ultimo_pago_aprobado: administracion.ultimo_pago_aprobado,
        },
      }));
  return true;
};
export const actualizarPagoRechazado = async (
  sucursal,
  pago,
  estado,
  valor,
  value,
  administracion
) => {
  await db
    .collection('sucursales')
    .doc(sucursal)
    .collection('pagos')
    .doc(pago)
    .update({
      estado: estado,
      fecha_actualizacion: new Date(),
      valor: parseFloat(valor),
      comentario_rechazo: value,
    });
  estado === 'aprobado' &&
    (await db
      .collection('sucursales')
      .doc(sucursal)
      .update({
        administracion: {
          pagos_pendientes: administracion.pagos_pendientes,
          monto_pendiente: administracion.monto_pendiente,
          ultima_fecha_aprobado: new Date(),
          ultima_fecha_pendiente: new Date(
            administracion.ultima_fecha_pendiente._seconds * 1000
          ),
          ultimo_pago_aprobado: valor,
        },
      }));
  return true;
};
export const actualizarPagoValor = async (
  sucursal,
  pago,
  estado,
  valor,
  valoractual,
  value,
  administracion
) => {
  await db
    .collection('sucursales')
    .doc(sucursal)
    .collection('pagos')
    .doc(pago)
    .update({
      estado: estado,
      fecha_actualizacion: new Date(),
      valor: parseFloat(valor),
      comentario_rechazo: value,
    });
  estado === 'pendiente' &&
    (await db
      .collection('sucursales')
      .doc(sucursal)
      .update({
        administracion: {
          pagos_pendientes: administracion.pagos_pendientes,
          monto_pendiente:
            administracion.monto_pendiente + parseFloat(valor) - valoractual,
          ultima_fecha_aprobado: new Date(),
          ultima_fecha_pendiente: new Date(
            administracion.ultima_fecha_pendiente._seconds * 1000
          ),
          ultimo_pago_aprobado: administracion.ultimo_pago_aprobado,
        },
      }));
  return true;
};

//HandleContactRechazados

export const contactoPago = async (sucursal, id_pago) => {
  await db
    .collection('sucursales')
    .doc(sucursal)
    .collection('pagos')
    .doc(id_pago)
    .update({
      contacto: true,
    });
  return true;
};

//Listar vendedores
export const listarVendedores = async (pais) => {
  let vendedores = [];
  await db
    .collection('vendedores')
    .where('pais', '==', pais)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        nombreCatalogo(pais, doc.data().catalogo).then((res) => {
          vendedores.push({
            id: doc.id,
            data: doc.data(),
            nombre_catalogo: res,
          });
        });
      });
    });
  return vendedores;
};

//Editar vendedores
export const editarVendedores = async (id, data) => {
  await db
    .collection('vendedores')
    .doc(id)
    .update({
      ...data,
    });
  return true;
};

// Nuevo Cliente

export const nuevoClienteVendedor = async (pais, id, cliente, completado) => {
  let id_cliente;
  await db
    .collection('paises')
    .doc(pais)
    .collection('clientesb2b')
    .add({
      vendedor: id,
      ...cliente,
      completado: completado,
    })
    .then((doc) => {
      id_cliente = doc.id;
    });
  return id_cliente;
};

// Editar Cliente
// export const editarClienteVendedor = async (
//   pais,
//   clienteActual,
//   cliente,
//   completado
// ) => {
//   await db
//     .collection('paises')
//     .doc(pais)
//     .collection('clientesb2b')
//     .doc(clienteActual)
//     .update({
//       ...cliente,
//       completado: completado,
//     });
//   return true;
// };

export const editarClienteVendedor = async (
  pais,
  clienteActual,
  cliente,
  completado
) => {
  await db
    .collection('paises')
    .doc(pais)
    .collection('clientesb2b')
    .doc(clienteActual)
    .update({
      vendedor: cliente.vendedor,
      ...cliente,
      completado: completado,
    });
  await db
    .collection('paises')
    .doc(pais)
    .collection('pedidosb2b')
    .get()
    .then((snapshot) => {
      snapshot.forEach(async (doc) => {
        if (doc.data().cliente.id === clienteActual) {
          await db
            .collection('paises')
            .doc(pais)
            .collection('pedidosb2b')
            .doc(doc.id)
            .update({
              cliente: {
                id: clienteActual,
                data: {
                  vendedor: cliente.vendedor,
                  ...cliente,
                  completado: completado,
                },
              },
            });
        }
      });
    });
  return true;
};

// Clientes de un vendedor

export const listaClientesVendedor = async (pais) => {
  let clientes = [];
  await db
    .collection('paises')
    .doc(pais)
    .collection('clientesb2b')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        nombreVendedor(doc.data().vendedor).then((res) => {
          clientes.push({ id: doc.id, data: doc.data(), nombre_vendedor: res });
        });
      });
    });

  return clientes;
};

// Nombre de vendedor
export const nombreVendedor = async (cliente) => {
  let nombre = '';
  await db
    .collection('vendedores')
    .doc(cliente)
    .get()
    .then((doc) => {
      nombre = doc.data().nombre;
    });
  return nombre;
};

// Descuentos MX

export const descuentosMX = async () => {
  let descuentos = [];
  let categoria;
  await db
    .collection('paises')
    .doc('mx')
    .collection('categorias')
    .where('nombre', '==', 'interna_no_usar')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        categoria = doc.id;
      });
    });
  await db
    .collection('paises')
    .doc('mx')
    .collection('productos')
    .where('categoria', '==', categoria)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        descuentos.push({
          id: doc.id,
          data: doc.data(),
          precio: doc.data().precios.precio1,
          id_erp_mx: doc.data().id_erp_mx.precio1,
        });
      });
    });
  return descuentos;
};

let datos_ar_actuales = {
  diasvencimiento: 9.5,
  horaszonaecharri: 72,
  primervencimiento: 0,
  segundovencimiento: 7,
  maximodeuda: 10000,
  minimodeuda: 200
}

let datos_mx_actuales = {
  diasvencimiento: 9.5,
  horaszonaecharri: 48,
  primervencimiento: 0,
  segundovencimiento: 7,
  maximodeuda: 2000,
  minimodeuda: 40
}

export const facturas = async (pais, sucursal, isWhiteList) => {
  let diasvencimiento;
  let facturas = [];
  await db
    .collection('paises')
    .doc(pais)
    .get()
    .then((doc) => {
      let data = doc.data();
      diasvencimiento = data.dias_vencimiento;
    });
  await db
    .collection('sucursales')
    .doc(sucursal)
    .collection('facturas')
    .orderBy('fecha_entrega', 'desc')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        let factura_ar_anterior = (pais === 'ar' && doc.data().fecha_factura.seconds < new Date(2022, 4, 9, 9, 0, 0) / 1000) || isWhiteList
        let factura_mx_anterior = (pais === 'mx' && doc.data().fecha_factura.seconds < new Date(2022, 5, 21, 9, 0, 0) / 1000) || isWhiteList
        let fecha_vencimiento =
          doc.data().fecha_factura.seconds + (pais === 'ar' ? (factura_ar_anterior ? datos_ar_actuales.diasvencimiento * 86400 : diasvencimiento * 86400 + 3 * 60 * 60) : (factura_mx_anterior ? datos_mx_actuales.diasvencimiento * 86400 : diasvencimiento * 86400 + 5 * 60 * 60));
        let vencida =
          hoy / 1000 - fecha_vencimiento > 0 && doc.data().deuda > 0
            ? true
            : false;
        facturas.push({ id: doc.id, data: doc.data(), vencida, fecha_vencimiento: new Date(fecha_vencimiento * 1000) });
      });
    });
  return facturas;
};

//Actualizar metodo de transacción
export const udpateTransactionReference = async (sucursal, paymentId, transactionId, transactionName) => {
  const sucursalesRef = db.collection('sucursales')
  const pagoDoc = await sucursalesRef.doc(sucursal).collection('pagos').doc(paymentId).update({
    transaction_reference: transactionId,
    transaction_name: transactionName
  })
  return true
}