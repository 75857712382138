import '../styles/globals.css';
import '../styles/StylesBulma.scss';

import { AdminProvider } from 'Context';

import 'bootstrap/dist/css/bootstrap.min.css';

function MyApp({ Component, pageProps }) {
  return (
    <AdminProvider>
      <Component {...pageProps} />
    </AdminProvider>
  );
}

export default MyApp;
